body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga' on;
  -moz-font-feature-settings: 'liga' on;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a.link,
a.link-complex {
  color: #ff1d25;
}

a.link:hover {
  border-bottom: 1px solid #ff1d25;
}

a.link-complex,
a.link-complex:hover,
a.link-complex:focus,
a.link-complex:active {
  border-bottom: none;
}

a.link-complex:hover .link-complex-target,
a.link-complex:focus .link-complex-target,
a.link-complex:active .link-complex-target {
  border-bottom: 1px solid #ff1d25;
}

.no-scroll {
  overflow: hidden;
}

abbr {
  cursor: help;
}
